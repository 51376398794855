import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/blog-post-layout.js";
import Date from '../../components/Post/date';
import PostsNavigation from '../../components/Post/posts-navigation';
import Newsletter from '../../components/Newsletter/newsletter';
import HeroPost from '../../components/Hero/hero-post';
import heroImg from '../../images/posts/003/form-is-there.svg';
import ModalSvg from '../../images/posts/003/modal.inline.svg';
import styles from './003-input-and-form.module.css';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <HeroPost gradientFrom="#E6DEE9" gradientTo="#FDCBF1" mdxType="HeroPost">
  <img src={heroImg} alt="Input is happy to see a Form" height="273" />
    </HeroPost>
    <h1>{`If there is an input, there should be a form`}</h1>
    <Date date="October 05, 2020" dateMachine="2020-10-05" mdxType="Date" />
    <p>{`I often see in Single Page apps a situation where someone uses just an `}<inlineCode parentName="p">{`<input>`}</inlineCode>{` field.
Sometimes in the accompaniment of `}<inlineCode parentName="p">{`<label>`}</inlineCode>{` if you happen to work with a pro 🌟. It feels that
when we gained control of inputs with two-way binding and we started handling `}<inlineCode parentName="p">{`onclick`}</inlineCode>{` events on
buttons with our fancy frameworks, we forgot the old way of doing things.`}</p>
    <h2>{`The old way, and the right way`}</h2>
    <p>{`The headline of this section may suggest that the old way and the right way are two different things.
Where in fact, it's the opposite. If you come back with your memory before the frameworks (or ajax) era
you see that people were using a `}<inlineCode parentName="p">{`<form>`}</inlineCode>{` element when dealing with inputs and buttons.`}</p>
    <p>{`In the past we were extensively using `}<inlineCode parentName="p">{`<form method="">`}</inlineCode>{` to process the input.
`}<strong parentName="p">{`I encourage you to still use a form element`}</strong>{`. It comes with benefits.`}</p>
    <h2>{`Common mistake`}</h2>
    <p>{`I use the keyboard to navigate and interact with pages. That speeds up everything. So for me one of the
biggest pitfalls of using `}<inlineCode parentName="p">{`<input>`}</inlineCode>{` without a `}<inlineCode parentName="p">{`<form>`}</inlineCode>{` is that the input is not auto-submittable, so to say.`}</p>
    <p>{`When you hit `}<inlineCode parentName="p">{`ENTER`}</inlineCode>{` in the field that is inside the form then the form is submitted.
That's very convenient for solo-input interfaces like a modal below:`}</p>
    <div className="container">
  <div className={styles.modalSvgContainer}>
    <ModalSvg alt="Modal with single input field" width="440" height="275" className={styles.modalSvg} mdxType="ModalSvg" />
  </div>
    </div>
    <p>{`You don't have to touch your mouse or touchpad and click `}<inlineCode parentName="p">{`Save`}</inlineCode>{` button 🎉. That would be a waste of time!`}</p>
    <h2>{`Solution`}</h2>
    <p>{`It's a common mistake to bind your action to a `}<inlineCode parentName="p">{`<button>`}</inlineCode>{` where in fact all you have to do is wrap your
`}<inlineCode parentName="p">{`<input>`}</inlineCode>{` with a `}<inlineCode parentName="p">{`<form>`}</inlineCode>{` and assign `}<inlineCode parentName="p">{`submit`}</inlineCode>{` event to a form:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html",
        "metastring": "{1,4}",
        "{1,4}": true
      }}>{`<form id="my-form">
  <input name="example" type="text" />
  <button type="submit">Submit</button>
</form>
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const form = document.getElementById('my-form');

form.addEventListener('submit', function handleSubmit(event) {
  alert('Submit clicked');
  event.preventDefault();
});
`}</code></pre>
    <p>{`Don't forget to prevent default behavior from firing and run your custom behavior. It could be running validation and
actually sending the data.`}</p>
    <p>{`One more thing that you should consider is always specifying button's type. It's `}<inlineCode parentName="p">{`submit`}</inlineCode>{` by default in most browsers
but Internet Explorer is different in this case and has `}<inlineCode parentName="p">{`button`}</inlineCode>{` as a default type.`}</p>
    <p>{`You can try this behavior on the form below:`}</p>
    <div className="container mt-40 mb-55">
  <Newsletter mdxType="Newsletter" />
    </div>
    <PostsNavigation prevTitle="Content Curation is going to be a job and how Content Marketing killed the web" prevLink="/posts/content-curation-and-marketing" nextTitle="Effective titles and more" nextLink="/posts/effective-titles-and-more" mdxType="PostsNavigation" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      